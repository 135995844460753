import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    "pt-BR": {
      translations: {
        index: {
          headingPart1: "Em sua jornada,",
          headingPart2: " ao seu lado",
          subHeading: "Assistência para você viajar pelo mundo",
          quoterHeading: "Cote agora o seu seguro viagem Assist 365",
          testimonials: {
            heading: "Sabemos como cuidar de você",
            subheading:
              "Ouvimos nossos clientes para oferecer a cobertura que atenda às suas necessidades.",
            content: {
              0: {
                name: "Rafael",
                country_code: "br",
                country_name: "São Paulo",
                content:
                  "O seguro da Assist 365 foi essencial para lidar com um imprevisto. Muito satisfeito!",
              },
              1: {
                name: "Ana L.",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "Viajei tranquila sabendo que estava protegida. Excelente atendimento da equipe!",
              },
              2: {
                name: "Gabriel S.",
                country_code: "cl",
                country_name: "Rio de Janeiro",
                content:
                  "Ótimo atendimento, rapidez e eficiência. O seguro viagem internacional foi um diferencial na minha viagem.",
              },
              3: {
                name: "Pedro S.",
                country_code: "ar",
                country_name: "Rio de Janeiro",
                content:
                  "O atendimento da Assist 365 foi fundamental em um pequeno acidente que tive em Bariloche.",
              },
              4: {
                name: "Jorge",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "A contratação foi simples e a atenção da equipe foi muito boa. A companhia aérea perdeu minha bagagem e me dera...",
              },
              5: {
                name: "Marilusse",
                country_code: "cl",
                country_name: "São Paulo",
                content:
                  "Mês passado, em um voo interno na Europa entre Amsterdã e Paris, uma das minhas malas não chegou. Entrei em...",
              },
            },
          },
          landings: {
            heading: "Viajá de manera segura a los tops destinos",
          },
          features: {
            1: {
              title: "Viaje tranquilo",
              content:
                "Trabalhamos 24 horas por dia, 7 dias por semana, para lhe dar o melhor serviço.",
            },
            2: {
              title: "Encontre o melhor para você",
              content:
                "Não importa se você está em uma pequena pausa do trabalho ou prestes a embarcar em um aeroporto. Contrate rápido e fácil.",
            },
            3: {
              title: "Não se preocupe se você mudar o destino",
              content:
                "Nossas coberturas estão disponíveis em todo o mundo. Onde quer que você vá, se preocupe apenas em aproveitar.",
            },
          },
          faqs: {
            0: {
              question: "O que é assistência de viagem?	",
              answer:
                "A assistência de viagem é um serviço que oferece cobertura médica e apoio em caso de emergências durante sua viagem. Com a ASSIST 365, você terá proteção médica, repatriação médica, assistência jurídica, cobertura para perda de bagagem, entre outros serviços essenciais para garantir sua tranquilidade durante a viagem.",
            },
            1: {
              question:
                "Qual é a diferença entre seguro de viagem e seguro médico de viagem?",
              answer:
                "O seguro de viagem oferece uma cobertura abrangente que inclui benefícios médicos, assistência em caso de atraso de voos, extravio de bagagem e outros imprevistos relacionados à sua viagem. Já o seguro médico de viagem se concentra principalmente no atendimento médico durante sua estadia no exterior, cobrindo despesas médicas, hospitalização e repatriação.",
            },
            2: {
              question:
                "Quais são os benefícios de contratar um seguro de viagem internacional?",
              answer:
                "O seguro de viagem internacional oferece proteção mundial. Isso significa que você estará coberto em caso de doença ou lesão durante sua viagem ao exterior, além de receber assistência em caso de perda de bagagem, atraso de voos ou outras eventualidades que possam afetar sua experiência de viagem. Isso é muito importante, pois os custos desses imprevistos no exterior costumam ser muito caros. Com o seguro de viagem internacional, você pode ter certeza de que está coberto.",
            },
            3: {
              question:
                "Como posso obter assistência de viagem com a ASSIST 365?",
              answer:
                "Obter assistência de viagem com a ASSIST 365 é rápido e fácil. Você só precisa entrar em nosso site, selecionar seu destino e as datas de sua viagem, e você terá diferentes opções de cobertura. Você pode escolher o plano que melhor se adapta às suas necessidades e fazer a compra on-line de forma segura e confiável.",
            },
            4: {
              question:
                "É obrigatório contratar um seguro de viagem para viajar da Argentina?",
              answer:
                "Embora não seja obrigatório para todos os destinos, é altamente recomendável fazer um seguro de viagem quando estiver viajando da Argentina. Isso lhe dará proteção e assistência médica em caso de emergências durante sua viagem, evitando despesas imprevistas e garantindo sua tranquilidade.",
            },
          },
        },
      },
    },
    es: {
      translations: {
        index: {
          headingPart1: "Em sua jornada,",
          headingPart2: " ao seu lado",
          subHeading: "Assistência para você viajar pelo mundo",
          quoterHeading: "Cote agora o seu seguro viagem Assist 365",
          testimonials: {
            heading: "Sabemos como cuidar de você",
            subheading:
              "Ouvimos nossos clientes para oferecer a cobertura que atenda às suas necessidades.",
            content: {
              0: {
                name: "Rafael",
                country_code: "br",
                country_name: "São Paulo",
                content:
                  "O seguro da Assist 365 foi essencial para lidar com um imprevisto. Muito satisfeito!",
              },
              1: {
                name: "Ana L.",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "Viajei tranquila sabendo que estava protegida. Excelente atendimento da equipe!",
              },
              2: {
                name: "Gabriel S.",
                country_code: "cl",
                country_name: "Rio de Janeiro",
                content:
                  "Ótimo atendimento, rapidez e eficiência. O seguro viagem internacional foi um diferencial na minha viagem.",
              },
              3: {
                name: "Pedro S.",
                country_code: "ar",
                country_name: "Rio de Janeiro",
                content:
                  "O atendimento da Assist 365 foi fundamental em um pequeno acidente que tive em Bariloche.",
              },
              4: {
                name: "Jorge",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "A contratação foi simples e a atenção da equipe foi muito boa. A companhia aérea perdeu minha bagagem e me dera...",
              },
              5: {
                name: "Marilusse",
                country_code: "cl",
                country_name: "São Paulo",
                content:
                  "Mês passado, em um voo interno na Europa entre Amsterdã e Paris, uma das minhas malas não chegou. Entrei em...",
              },
            },
          },
          landings: {
            heading: "Viajá de manera segura a los tops destinos",
          },
          features: {
            1: {
              title: "Viaje tranquilo",
              content:
                "Trabalhamos 24 horas por dia, 7 dias por semana, para lhe dar o melhor serviço.",
            },
            2: {
              title: "Encontre o melhor para você",
              content:
                "Não importa se você está em uma pequena pausa do trabalho ou prestes a embarcar em um aeroporto. Contrate rápido e fácil.",
            },
            3: {
              title: "Não se preocupe se você mudar o destino",
              content:
                "Nossas coberturas estão disponíveis em todo o mundo. Onde quer que você vá, se preocupe apenas em aproveitar.",
            },
          },
          faqs: {
            0: {
              question: "O que é assistência de viagem?	",
              answer:
                "A assistência de viagem é um serviço que oferece cobertura médica e apoio em caso de emergências durante sua viagem. Com a ASSIST 365, você terá proteção médica, repatriação médica, assistência jurídica, cobertura para perda de bagagem, entre outros serviços essenciais para garantir sua tranquilidade durante a viagem.",
            },
            1: {
              question:
                "Qual é a diferença entre seguro de viagem e seguro médico de viagem?",
              answer:
                "O seguro de viagem oferece uma cobertura abrangente que inclui benefícios médicos, assistência em caso de atraso de voos, extravio de bagagem e outros imprevistos relacionados à sua viagem. Já o seguro médico de viagem se concentra principalmente no atendimento médico durante sua estadia no exterior, cobrindo despesas médicas, hospitalização e repatriação.",
            },
            2: {
              question:
                "Quais são os benefícios de contratar um seguro de viagem internacional?",
              answer:
                "O seguro de viagem internacional oferece proteção mundial. Isso significa que você estará coberto em caso de doença ou lesão durante sua viagem ao exterior, além de receber assistência em caso de perda de bagagem, atraso de voos ou outras eventualidades que possam afetar sua experiência de viagem. Isso é muito importante, pois os custos desses imprevistos no exterior costumam ser muito caros. Com o seguro de viagem internacional, você pode ter certeza de que está coberto.",
            },
            3: {
              question:
                "Como posso obter assistência de viagem com a ASSIST 365?",
              answer:
                "Obter assistência de viagem com a ASSIST 365 é rápido e fácil. Você só precisa entrar em nosso site, selecionar seu destino e as datas de sua viagem, e você terá diferentes opções de cobertura. Você pode escolher o plano que melhor se adapta às suas necessidades e fazer a compra on-line de forma segura e confiável.",
            },
            4: {
              question:
                "É obrigatório contratar um seguro de viagem para viajar da Argentina?",
              answer:
                "Embora não seja obrigatório para todos os destinos, é altamente recomendável fazer um seguro de viagem quando estiver viajando da Argentina. Isso lhe dará proteção e assistência médica em caso de emergências durante sua viagem, evitando despesas imprevistas e garantindo sua tranquilidade.",
            },
          },
        },
      },
    },
    "es-AR": {
      translations: {
        index: {
          headingPart1: "Em sua jornada,",
          headingPart2: " ao seu lado",
          subHeading: "Assistência para você viajar pelo mundo",
          quoterHeading: "Cote agora o seu seguro viagem Assist 365",
          testimonials: {
            heading: "Sabemos como cuidar de você",
            subheading:
              "Ouvimos nossos clientes para oferecer a cobertura que atenda às suas necessidades.",
            content: {
              0: {
                name: "Rafael",
                country_code: "br",
                country_name: "São Paulo",
                content:
                  "O seguro da Assist 365 foi essencial para lidar com um imprevisto. Muito satisfeito!",
              },
              1: {
                name: "Ana L.",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "Viajei tranquila sabendo que estava protegida. Excelente atendimento da equipe!",
              },
              2: {
                name: "Gabriel S.",
                country_code: "cl",
                country_name: "Rio de Janeiro",
                content:
                  "Ótimo atendimento, rapidez e eficiência. O seguro viagem internacional foi um diferencial na minha viagem.",
              },
              3: {
                name: "Pedro S.",
                country_code: "ar",
                country_name: "Rio de Janeiro",
                content:
                  "O atendimento da Assist 365 foi fundamental em um pequeno acidente que tive em Bariloche.",
              },
              4: {
                name: "Jorge",
                country_code: "mx",
                country_name: "Mina Gerais",
                content:
                  "A contratação foi simples e a atenção da equipe foi muito boa. A companhia aérea perdeu minha bagagem e me dera...",
              },
              5: {
                name: "Marilusse",
                country_code: "cl",
                country_name: "São Paulo",
                content:
                  "Mês passado, em um voo interno na Europa entre Amsterdã e Paris, uma das minhas malas não chegou. Entrei em...",
              },
            },
          },
          landings: {
            heading: "Viajá de manera segura a los tops destinos",
          },
          features: {
            1: {
              title: "Viaje tranquilo",
              content:
                "Trabalhamos 24 horas por dia, 7 dias por semana, para lhe dar o melhor serviço.",
            },
            2: {
              title: "Encontre o melhor para você",
              content:
                "Não importa se você está em uma pequena pausa do trabalho ou prestes a embarcar em um aeroporto. Contrate rápido e fácil.",
            },
            3: {
              title: "Não se preocupe se você mudar o destino",
              content:
                "Nossas coberturas estão disponíveis em todo o mundo. Onde quer que você vá, se preocupe apenas em aproveitar.",
            },
          },
          faqs: {
            0: {
              question: "O que é assistência de viagem?	",
              answer:
                "A assistência de viagem é um serviço que oferece cobertura médica e apoio em caso de emergências durante sua viagem. Com a ASSIST 365, você terá proteção médica, repatriação médica, assistência jurídica, cobertura para perda de bagagem, entre outros serviços essenciais para garantir sua tranquilidade durante a viagem.",
            },
            1: {
              question:
                "Qual é a diferença entre seguro de viagem e seguro médico de viagem?",
              answer:
                "O seguro de viagem oferece uma cobertura abrangente que inclui benefícios médicos, assistência em caso de atraso de voos, extravio de bagagem e outros imprevistos relacionados à sua viagem. Já o seguro médico de viagem se concentra principalmente no atendimento médico durante sua estadia no exterior, cobrindo despesas médicas, hospitalização e repatriação.",
            },
            2: {
              question:
                "Quais são os benefícios de contratar um seguro de viagem internacional?",
              answer:
                "O seguro de viagem internacional oferece proteção mundial. Isso significa que você estará coberto em caso de doença ou lesão durante sua viagem ao exterior, além de receber assistência em caso de perda de bagagem, atraso de voos ou outras eventualidades que possam afetar sua experiência de viagem. Isso é muito importante, pois os custos desses imprevistos no exterior costumam ser muito caros. Com o seguro de viagem internacional, você pode ter certeza de que está coberto.",
            },
            3: {
              question:
                "Como posso obter assistência de viagem com a ASSIST 365?",
              answer:
                "Obter assistência de viagem com a ASSIST 365 é rápido e fácil. Você só precisa entrar em nosso site, selecionar seu destino e as datas de sua viagem, e você terá diferentes opções de cobertura. Você pode escolher o plano que melhor se adapta às suas necessidades e fazer a compra on-line de forma segura e confiável.",
            },
            4: {
              question:
                "É obrigatório contratar um seguro de viagem para viajar da Argentina?",
              answer:
                "Embora não seja obrigatório para todos os destinos, é altamente recomendável fazer um seguro de viagem quando estiver viajando da Argentina. Isso lhe dará proteção e assistência médica em caso de emergências durante sua viagem, evitando despesas imprevistas e garantindo sua tranquilidade.",
            },
          },
        },
      },
    },
  },
  lng: "pt-BR",
  fallbackLng: "pt-BR",
  ns: ["translations"],
  defaultNS: "translations",
});
export default i18n;
